<template>
  <section id="container" class="full-width">
    <!--header start-->
    <header class="header black-bg">
      <div class="navbar-header">
        <!--logo start-->
        <a href="/order" class="logo text-white"
          ><img src="/img/CreoNordicLogohvit.png" class="logoimg" /> CreoNordic
        </a>
      </div>
    </header>

    <section id="main-content">
      <section class="wrapper">
        <!-- page start-->
        <div class="row mt-4">
          <div class="col-lg-12">
            <div>
              <p v-if="loggedIn">{{ $t("message.hello") }}</p>
              <div v-else>
                <Login-Form />
              </div>
            </div>
          </div>
        </div>
      </section>
    </section>

    <!--header end-->
    <!--sidebar start-->
  </section>
</template>

<script>
import LoginForm from "@/components/LoginForm.vue";
import autentication from "@/store/modules/autentication.js";

export default {
  name: "home",
  components: {
    LoginForm
  },
  data() {
    return {
      loggedIn: autentication.getters.isAuthenticated
    };
  }
};
</script>
