<template>
  <div class="container">
    <b-alert v-if="errormsg" show class="text-center" variant="danger">{{
      $t("login_error_message")
    }}</b-alert>
    <div v-if="loading" class="text-center mt-5">
      <b-spinner label="Loading..."></b-spinner>
    </div>
    <form v-else class="form-signin" @submit.prevent="loginUser">
      <h2 class="form-signin-heading">sign in</h2>
      <div class="login-wrap">
        <input
          type="text"
          class="form-control"
          v-model="username"
          required="required"
          placeholder="User ID"
          autofocus
        />
        <input
          type="password"
          v-model="password"
          required="required"
          class="form-control"
          placeholder="Password"
        />
        <label class="checkbox">
          <a data-toggle="modal" href="#myModal"> Forgot Password?</a>
        </label>
        <button class="btn btn-lg btn-login btn-block" type="submit">
          Sign in
        </button>
      </div>

      <!-- Modal -->
      <div
        aria-hidden="true"
        aria-labelledby="myForgotPasswordModal"
        role="dialog"
        tabindex="-1"
        id="myModal"
        class="modal fade"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title">Forgot Password ?</h4>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <p>Enter your e-mail address below to reset your password.</p>
              <input
                type="text"
                name="email"
                v-model="email"
                placeholder="Email"
                autocomplete="off"
                class="form-control placeholder-no-fix"
              />
            </div>
            <div class="modal-footer">
              <button
                data-dismiss="modal"
                class="btn btn-default"
                type="button"
              >
                Cancel
              </button>
              <button
                class="btn btn-success"
                data-dismiss="modal"
                type="button"
                @click="resetPassword"
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
      <!-- modal end -->
    </form>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "LoginForm",
  data() {
    return {
      username: "",
      password: "",
      email: "",
      errormsg: null,
      loading: false
    };
  },
  methods: {
    ...mapActions(["logIn", "forgotPassword"]),
    loginUser() {
      this.loading = true;
      this.logIn({
        username: this.username,
        password: this.password
      })
        .then(() => {
          this.$router.push("/");
        })
        .catch(error => {
          this.errormsg = error;
          this.password = "";
          this.loading = false;
        });
    },
    resetPassword() {
      this.loading = true;
      this.forgotPassword(this.email)
        .then(() => {
          this.loading = false;
          this.$router.push("/");
        })
        .catch(() => {
          this.loading = false;
        });
    }
  }
};
</script>

<style>
body {
  color: #797979;
  background: #f1f2f7;
  font-family: "Open Sans", sans-serif;
  padding: 0px !important;
  margin: 0px !important;
  font-size: 13px;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
}

#container {
  width: 100%;
  height: 100%;
  position: relative;
}

/*login page*/

.login-body {
  background-color: #f1f2f7;
}

.form-signin {
  max-width: 330px;
  margin: 50px auto 0;
  background: #fff;
  border-radius: 5px;
  -webkit-border-radius: 5px;
}

.form-signin h2.form-signin-heading {
  margin: 0;
  padding: 20px 15px;
  text-align: center;
  background: #000000;
  border-radius: 5px 5px 0 0;
  -webkit-border-radius: 5px 5px 0 0;
  color: #fff;
  font-size: 18px;
  text-transform: uppercase;
  font-weight: 300;
  font-family: "Open Sans", sans-serif;
}

.form-signin .checkbox {
  margin-bottom: 14px;
}
.form-signin .checkbox {
  font-weight: normal;
  color: #b6b6b6;
  font-weight: 300;
  font-family: "Open Sans", sans-serif;
}
.form-signin .form-control {
  position: relative;
  font-size: 16px;
  height: auto;
  padding: 10px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.form-signin .form-control:focus {
  z-index: 2;
}
.form-signin input[type="text"],
.form-signin input[type="password"] {
  margin-bottom: 15px;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  border: 1px solid #eaeaea;
  box-shadow: none;
  font-size: 12px;
}

.form-signin .btn-login {
  background: #09971cda;
  color: #0d0d0d;
  text-transform: uppercase;
  /* font-weight: 300; */
  font-family: "Open Sans", sans-serif;
  box-shadow: 0 4px #09971cda;
  margin-bottom: 20px;
}

.form-signin p {
  text-align: center;
  color: #b6b6b6;
  font-size: 16px;
  font-weight: 300;
}

.form-signin a {
  color: #09971cda;
}

.form-signin a:hover {
  color: #b6b6b6;
}

.login-wrap {
  padding: 20px;
}

.login-social-link {
  display: inline-block;
  margin-top: 20px;
  margin-bottom: 15px;
}

.login-social-link a {
  color: #fff;
  padding: 15px 28px;
  border-radius: 4px;
}

.login-social-link a:hover {
  color: #fff;
}

.login-social-link a i {
  font-size: 20px;
  padding-right: 10px;
}

.login-social-link a.facebook {
  background: #5193ea;
  margin-right: 22px;
  box-shadow: 0 4px #2775e2;
  float: left;
}

.login-social-link a.twitter {
  background: #44ccfe;
  box-shadow: 0 4px #2bb4e8;
  float: left;
}
</style>
